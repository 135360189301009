var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12 col-md-2" },
          [
            _c(
              "q-list",
              { attrs: { bordered: "", padding: "" } },
              [
                _c(
                  "q-item",
                  {
                    directives: [{ name: "ripple", rawName: "v-ripple" }],
                    attrs: { tag: "label" },
                  },
                  [
                    _c(
                      "q-item-section",
                      { attrs: { side: "", top: "" } },
                      [
                        _c("q-checkbox", {
                          nativeOn: {
                            click: function ($event) {
                              return _vm.onSelezionaCheck()
                            },
                          },
                          model: {
                            value: _vm.selezione_rapida,
                            callback: function ($$v) {
                              _vm.selezione_rapida = $$v
                            },
                            expression: "selezione_rapida",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", [
                          _vm._v("Seleziona/Deseleziona tutto"),
                        ]),
                        _c("q-item-label", { attrs: { caption: "" } }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("q-separator", { attrs: { spaced: "" } }),
                _c(
                  "q-item",
                  {
                    directives: [{ name: "ripple", rawName: "v-ripple" }],
                    attrs: { tag: "label" },
                  },
                  [
                    _c(
                      "q-item-section",
                      { attrs: { side: "", top: "" } },
                      [
                        _c("q-checkbox", {
                          nativeOn: {
                            click: function ($event) {
                              return _vm.onAggiornaFiltroTabella()
                            },
                          },
                          model: {
                            value: _vm.filtro_ricerca.attesa_lavorazione,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.filtro_ricerca,
                                "attesa_lavorazione",
                                $$v
                              )
                            },
                            expression: "filtro_ricerca.attesa_lavorazione",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", [
                          _vm._v("In attesa di lavorazione"),
                        ]),
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v(
                            " Pratiche appena richieste ma ancora non prese in carico "
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "q-item",
                  {
                    directives: [{ name: "ripple", rawName: "v-ripple" }],
                    attrs: { tag: "label" },
                  },
                  [
                    _c(
                      "q-item-section",
                      { attrs: { side: "", top: "" } },
                      [
                        _c("q-checkbox", {
                          nativeOn: {
                            click: function ($event) {
                              return _vm.onAggiornaFiltroTabella()
                            },
                          },
                          model: {
                            value: _vm.filtro_ricerca.in_lavorazione,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.filtro_ricerca,
                                "in_lavorazione",
                                $$v
                              )
                            },
                            expression: "filtro_ricerca.in_lavorazione",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", [_vm._v("In lavorazione")]),
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v(
                            " Pratiche prese in carico e in fase di emissione "
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "q-item",
                  {
                    directives: [{ name: "ripple", rawName: "v-ripple" }],
                    attrs: { tag: "label" },
                  },
                  [
                    _c(
                      "q-item-section",
                      { attrs: { side: "", top: "" } },
                      [
                        _c("q-checkbox", {
                          nativeOn: {
                            click: function ($event) {
                              return _vm.onAggiornaFiltroTabella()
                            },
                          },
                          model: {
                            value: _vm.filtro_ricerca.attesa_intervento_ufficio,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.filtro_ricerca,
                                "attesa_intervento_ufficio",
                                $$v
                              )
                            },
                            expression:
                              "filtro_ricerca.attesa_intervento_ufficio",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", [
                          _vm._v("Attesa intervento Ufficio"),
                        ]),
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v(
                            " Pratiche su cui sono stati riscontrati errori e su cui è richiesto l'intervento dell'Ufficio "
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "q-item",
                  {
                    directives: [{ name: "ripple", rawName: "v-ripple" }],
                    attrs: { tag: "label" },
                  },
                  [
                    _c(
                      "q-item-section",
                      { attrs: { side: "", top: "" } },
                      [
                        _c("q-checkbox", {
                          nativeOn: {
                            click: function ($event) {
                              return _vm.onAggiornaFiltroTabella()
                            },
                          },
                          model: {
                            value:
                              _vm.filtro_ricerca.attesa_intervento_backoffice,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.filtro_ricerca,
                                "attesa_intervento_backoffice",
                                $$v
                              )
                            },
                            expression:
                              "filtro_ricerca.attesa_intervento_backoffice",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", [
                          _vm._v("Attesa intervento BackOffice"),
                        ]),
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v(
                            " Pratiche su cui sono stati riscontrati errori e su cui è richiesto l'intervento del BackOffice "
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "q-item",
                  {
                    directives: [{ name: "ripple", rawName: "v-ripple" }],
                    attrs: { tag: "label" },
                  },
                  [
                    _c(
                      "q-item-section",
                      { attrs: { side: "", top: "" } },
                      [
                        _c("q-checkbox", {
                          nativeOn: {
                            click: function ($event) {
                              return _vm.onAggiornaFiltroTabella()
                            },
                          },
                          model: {
                            value: _vm.filtro_ricerca.definite,
                            callback: function ($$v) {
                              _vm.$set(_vm.filtro_ricerca, "definite", $$v)
                            },
                            expression: "filtro_ricerca.definite",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", [_vm._v("Definite")]),
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v(" Pratiche attive "),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "q-item",
                  {
                    directives: [{ name: "ripple", rawName: "v-ripple" }],
                    attrs: { tag: "label" },
                  },
                  [
                    _c(
                      "q-item-section",
                      { attrs: { side: "", top: "" } },
                      [
                        _c("q-checkbox", {
                          nativeOn: {
                            click: function ($event) {
                              return _vm.onAggiornaFiltroTabella()
                            },
                          },
                          model: {
                            value: _vm.filtro_ricerca.annullate,
                            callback: function ($$v) {
                              _vm.$set(_vm.filtro_ricerca, "annullate", $$v)
                            },
                            expression: "filtro_ricerca.annullate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", [_vm._v("Annullate")]),
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v(" Pratiche annullate o disdettate "),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-12 col-md-10 q-px-md" },
          [
            _c(
              "div",
              { attrs: { align: "center" } },
              [
                _c("QQButton", {
                  staticClass: "q-mb-sm",
                  attrs: {
                    label: "Aggiorna elenco",
                    color: "blue-grey",
                    icon: "mdi-database-refresh-outline",
                    size: "sm",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.aggiornaListaPratiche.apply(null, arguments)
                    },
                  },
                }),
              ],
              1
            ),
            _c("hr"),
            _c("q-table", {
              staticClass: "my-sticky-header-table no-box-shadow q-mt-none",
              attrs: {
                title:
                  "Clicca su una pratica per attivare le opzioni di gestione",
                data: _vm.righe,
                "wrap-cells": "",
                columns: _vm.colonne,
                filter: _vm.filter,
                separator: "cell",
                "row-key": "numero_interno",
                "rows-per-page-options": [20, 40, 60, 80],
              },
              on: { "row-click": _vm.selezionaRecordPratiche },
              scopedSlots: _vm._u([
                {
                  key: "top-right",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "panel-cerca" },
                        [
                          _c("q-input", {
                            staticStyle: { "min-width": "350px" },
                            attrs: {
                              outlined: "",
                              dense: "",
                              debounce: "300",
                              placeholder: "Cerca",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "append",
                                fn: function () {
                                  return [
                                    _c("q-icon", { attrs: { name: "search" } }),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.filter,
                              callback: function ($$v) {
                                _vm.filter = $$v
                              },
                              expression: "filter",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "header",
                  fn: function (props) {
                    return [
                      _c(
                        "q-tr",
                        { attrs: { props: props } },
                        _vm._l(props.cols, function (col) {
                          return _c(
                            "q-th",
                            {
                              key: col.name,
                              staticStyle: {
                                "font-size": "14px",
                                "border-bottom": "1px SOLID #404040",
                                "background-color": "#ffcc99",
                                color: "#000",
                              },
                              attrs: { props: props },
                            },
                            [_c("strong", [_vm._v(_vm._s(col.label))])]
                          )
                        }),
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "body",
                  fn: function (props) {
                    return [
                      _c(
                        "q-tr",
                        {
                          staticStyle: { cursor: "pointer" },
                          attrs: { props: props },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.selezionaRecordPratiche(
                                null,
                                props.row
                              )
                            },
                          },
                        },
                        [
                          _c("q-td", { attrs: { "auto-width": "" } }, [
                            _vm._v(" " + _vm._s(props.cols[0].value) + " "),
                          ]),
                          _c("q-td", { attrs: { "auto-width": "" } }, [
                            _vm._v(" " + _vm._s(props.cols[1].value) + " "),
                          ]),
                          _c("q-td", { attrs: { "auto-width": "" } }, [
                            _vm._v(" " + _vm._s(props.cols[2].value) + " "),
                          ]),
                          _c("q-td", { attrs: { "auto-width": "" } }, [
                            _vm._v(" " + _vm._s(props.cols[3].value) + " "),
                          ]),
                          _c("q-td", { attrs: { "auto-width": "" } }, [
                            _vm._v(" " + _vm._s(props.cols[4].value) + " "),
                          ]),
                          _c("q-td", {
                            attrs: { "auto-width": "" },
                            domProps: {
                              innerHTML: _vm._s(props.cols[5].value),
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _c("hr"),
      ]),
      _c(
        "q-dialog",
        {
          attrs: {
            persistent: "",
            "transition-show": "scale",
            "transition-hide": "scale",
          },
          model: {
            value: _vm.persistent,
            callback: function ($$v) {
              _vm.persistent = $$v
            },
            expression: "persistent",
          },
        },
        [
          _c(
            "q-card",
            {
              staticClass: "bg-teal text-white shadow",
              staticStyle: { width: "500px" },
            },
            [
              _c("q-card-section", [
                _c("div", { staticClass: "text-h6" }, [
                  _vm._v("Seleziona comando"),
                ]),
              ]),
              _c("q-card-section", { staticClass: "q-pt-none" }, [
                _c(
                  "div",
                  { staticClass: "row justify-center" },
                  [
                    _vm.isPulsanteComandiVisible("IN ATTESA DI LAVORAZIONE")
                      ? _c("QQButton", {
                          attrs: {
                            label: "Prendi in carico",
                            color: "blue-grey",
                            icon: "mdi-pencil-ruler",
                            size: "sm",
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.onSelezionaPratica.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _c(
                "q-card-actions",
                {
                  staticClass: "bg-white text-teal",
                  attrs: { align: "right" },
                },
                [
                  _c("q-btn", {
                    directives: [
                      { name: "close-popup", rawName: "v-close-popup" },
                    ],
                    attrs: { flat: "", label: "ANNULLA" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("br"),
      _c("br"),
      _c("br"),
      _c("br"),
      _c("br"),
      _c("br"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }